import React, { useState } from 'react';
import styled from 'styled-components';
import { IMAGE } from '../constants';
import { DetailModal } from './modal';

export type CircleImage = {
  path: string;
  name: string;
  price: number;
  descriptions?: string[];
};
export type CircleImagesProps = {
  images: CircleImage[];
};

export default function CircleImages({ images }: CircleImagesProps) {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [currentImage, setCurrentImage] = useState<CircleImage | undefined>(
    undefined
  );

  function onClickImage(img: CircleImage) {
    setCurrentImage(img);
    setIsOpenModal(true);
  }

  function onCloseModal() {
    setCurrentImage(undefined);
    setIsOpenModal(false);
  }

  return (
    <>
      <Images>
        {images.map((image, i) => (
          <Image key={i} onClick={() => onClickImage(image)}>
            <img
              src={image.path}
              alt={image.name}
              onError={(e) => {
                const target: HTMLImageElement = e.target as HTMLImageElement;
                target.onerror = null;
                target.src = IMAGE.NO_IMAGE;
              }}
            />
            <Description>
              <Title>{image.name}</Title>
              <p>&yen;{image.price.toLocaleString()}（税込）</p>
            </Description>
          </Image>
        ))}
      </Images>

      {/* 詳細モーダル */}
      {isOpenModal && currentImage && (
        <DetailModal
          title={currentImage.name}
          details={currentImage.descriptions || []}
          imagePath={currentImage.path}
          price={currentImage.price}
          onClose={onCloseModal}
        />
      )}
    </>
  );
}

const Images = styled.div`
  display: inline-flex;
  overflow: scroll;
  width: 100%;
`;

const Title = styled.p`
  font-weight: bold;
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
`;

const Image = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem;
  min-width: 32%;

  > img {
    width: 100%;
    border-radius: 8px;
    border: solid #000 2px;
    outline: solid #000 2px;
    outline-offset: 4px;
    object-fit: cover;
    margin: 8px;
  }
`;
