import { getAnalytics } from 'firebase/analytics';
import * as React from 'react';
import * as ReactDOM from 'react-dom/client';

import App from './App';
import { ERROR_MESSAGE } from '@domains/common/constants';
import firebase from '@vendors/firebase';

// Firebase Analytics
if (process.env.NODE_ENV === 'production') {
  getAnalytics(firebase);
}

// Rendering React DOM
const root = document.getElementById('root');

if (root) {
  ReactDOM.createRoot(root).render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
} else {
  console.error(new Error(ERROR_MESSAGE.FAILED_MOUNT_ROOT));
}
