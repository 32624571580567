import { initializeApp } from 'firebase/app';

// Initialize Firebase
const app = initializeApp({
  apiKey: 'AIzaSyDyrU-uOFtlzVgli-BSQ49Y5H2g3lBD6hg',
  authDomain: 'homepage-a8541.firebaseapp.com',
  projectId: 'homepage-a8541',
  storageBucket: 'homepage-a8541.appspot.com',
  messagingSenderId: '625757756903',
  appId: '1:625757756903:web:fc33f6e15eb3fa8d84b142',
  measurementId: 'G-Q1Y15JR8TF',
});

export default app;
