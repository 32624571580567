import React from 'react';
import CircleImages from '@common/components/circle_images';
import { HeaderLarge } from '@common/components/text';
import { COURSES } from './constant';

export default function Course() {
  return (
    <>
      <HeaderLarge $my={1}>Course Meals</HeaderLarge>
      <CircleImages images={COURSES} />
    </>
  );
}
