import React from 'react';
import CircleImages from '@common/components/circle_images';
import { HeaderLarge } from '@common/components/text';
import { BEERS, WHISKYS } from './constant';

export default function Drink() {
  return (
    <>
      <HeaderLarge $my={1}>Beers</HeaderLarge>
      <CircleImages images={BEERS} />
      <HeaderLarge $my={1}>{'Whisk(e)ys'}</HeaderLarge>
      <CircleImages images={WHISKYS} />
    </>
  );
}
