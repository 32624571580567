import { CircleImage } from '@common/components/circle_images';

export const FOODS: CircleImage[] = [
  {
    name: '肉盛りプレートスペシャル',
    price: 3000,
    path: 'https://lh3.googleusercontent.com/pw/ABLVV85Uld8aQpORLNqYYOQWwfKq2syHfkAQpQBZEMnDOTeG4rkwzqPJuw5Sw12IifatqAk9ZZ4YSWDUirm-OcNg-ujbJVX3mzwBQhFgwuwp37gqSriocjXX8LygkJiKUUZIFd91TQnn7M1O6KwuO7dlWMBL=w480-h480-s-no-gm?authuser=0',
    descriptions: [
      '短角牛ランプ100ｇ、牛ハラミ100ｇ、三元豚白王ロース100ｇソースを2種類お選びいただけます。',
    ],
  },
  {
    name: '厚切りベーコン',
    price: 1200,
    path: 'https://lh3.googleusercontent.com/pw/ABLVV86hCQllIJn-FG7sYuv2l6sZfSD9PgOa1ayU4eQ6SrJMYEwsBKkUm4nT5ENZKRYRbKF6WQxbLjp_8CcTXap4mmsIul0KdC0sPw5hCzqFWtL8lY2VzCnnUUTeynuBbrE26ripCSU05TLip4INsoVXOEjg=w480-h480-s-no-gm?authuser=0',
    descriptions: [
      'ボリューム満点！',
      '燻製ベーコンのスモーキーさでビールが進みます！',
    ],
  },
  {
    name: '極太ソーセージ2本',
    price: 1200,
    path: 'https://lh3.googleusercontent.com/pw/ABLVV84cW0BtrGe2GzYqTCd4txwu30GMTXDKhv4mcaN7QknGkLCNnaqyiOTMdK-wdQBfrxhpGQ_tU2ea3Y-QKdYHOAtkXd2MFW87sW_1PRjQ70NvSeEAF9_7vgdwDdRR5hrmGIG7C29Q4JLAZ7Lur1t4yB3r=w480-h480-s-no-gm?authuser=0',
    descriptions: ['肉汁が溢れ出す大きな生ソーセージをじっくり焼き上げます！'],
  },
  {
    name: '三元豚白王ロースステーキ',
    price: 1500,
    path: 'https://lh3.googleusercontent.com/pw/ABLVV84kCfBh8aFooDmYrorqFJZN2KdY3TDliZYluGQbWL7zAbIU1YJKQjSlR-vlSAx2Y4TL5dYuQWYZRo_Cnw0RrdUFR38Od0vxJ4pXlI5f-gNKFvb2PHYVghC5Xa7qWxjmLCZ_EnpCoipaVye6O_BvCV2h=w480-h480-s-no-gm?authuser=0',
    descriptions: [
      '甘みと旨味が強い三元豚白王ロース肉は自家製サワーハニーマスタードソースと相性◎',
    ],
  },
  {
    name: '牛ハラミステーキ',
    price: 1500,
    path: 'https://lh3.googleusercontent.com/pw/ABLVV866FbSHr_8rKQqVT5HeT4iWXXZqL1fEJaReGqC5x4hpDfaJyiahW6pESUIQShcibd9GtTMwnYDm_cH9KiI_erBMhqEW4DkWo_UrP1MAvUoyXTz4aBgcdW6RCFANH-paqN_tKYERR2OKurqobwwxlpQq=w480-h480-s-no-gm?authuser=0',
    descriptions: ['柔らかくジューシーで、特製オニオンソースと良く合います！'],
  },
  {
    name: '短角牛ランプステーキ',
    price: 1800,
    path: 'https://lh3.googleusercontent.com/pw/ABLVV87U02Duj4lbWUdjR6AyMRwqdS0tgjPcc_dpmTjagsKOh3AEE2p274CWsyd9jqAT9KKqYtTjh7XHKPY0l5T5brmYjd78ldjE51PniBdsEGWmX4-pSPAEPYEk13R27d1oxzzcEnDOasNHjdcmxoJ6jzfW=w480-h480-s-no-gm?authuser=0',
    descriptions: [
      '赤身の一番人気のランプを贅沢に！',
      'ガーリックソースやバーベキューソースでガッツリと！',
    ],
  },
  {
    name: '自家製ローストビーフ',
    price: 500,
    path: 'https://lh3.googleusercontent.com/pw/ABLVV86mhUNjSmFGHYNCwftDU3EbPuuLNvRlYA6CMyjQaU8GJQX6Co9jQHRIRCDIlib150_FKzPxKKwzD3TEuen263RlWxQ6olE8lH3yHzBCfQcLtkbuLVatIZaz6H0RTcOq5EDlXDQ97oP8Df7tciEZ9c-0=w1536-h1536-s-no-gm?authuser=0',
    descriptions: ['月曜日～木曜日限定メニュー！', '限定１０皿になります！'],
  },
  {
    name: 'フリッタータ',
    price: 500,
    path: 'https://lh3.googleusercontent.com/pw/ABLVV84hqj5XHU1OsOmIjXcwAMwPxyzQ1Eu96U940E28ywEZpnM2P-ztyUGB7MxrYceQqfxsCfuLsFQ60eewAnGIBXbSj8V9ClFQJ2WPo6zqL7gmb3y539etyObISxlQ9znf7o2xBhd-hZ1GN-LmZX-6ErZI=w1536-h1536-s-no-gm?authuser=0',
    descriptions: ['冷製オムレツです！'],
  },
  {
    name: 'ブラックペッパーがきいた鶏レバーのパテ',
    price: 500,
    path: 'https://lh3.googleusercontent.com/pw/ADCreHcZNbJLUaAI_JLzIbd5QoDz0I84d9pe0M99rpIa0oYyEZ56m60bMB8Bkm2aVRmERYOLSOQ1ZGmA6RvURKlk7b_eU2upLfp2pGWIaUZ3z793MN-xwD93Pja97noRId4QrVsBnkWREY5n1QpOgRlHXQ2S=w1280-h1280-s-no?authuser=0',
    descriptions: ['お店でいちから仕込んだハーブたっぷりのレバーパテ'],
  },
  {
    name: 'ホタテとブロッコリーのアーリオオーリオ',
    price: 500,
    path: 'https://lh3.googleusercontent.com/pw/ABLVV87YX2DLgbck0Wp6VKX6kCRbiuy-z2wDQqTIep-9x9jzWKA19qb0w1ZIzhGqMMHiMfOfoCGSrXP2TEq0QC3-xWPCUXDYBWrwndsTtrH_V2bGbYZJafMSdZwYv2xciWgw-Kd1nbw84j_sMIk82rj7jVD_=w1536-h1536-s-no-gm?authuser=0',
  },
  {
    name: '自家製ピクルス',
    price: 500,
    path: 'https://lh3.googleusercontent.com/pw/ADCreHfhRlZfoPsXvUagrqn_7x22qGS8dAH0hZes42XzsRjaFl4CMbsWLvRll0Gg_hnDlj_6_beMlwhtqMLEZsjrldOBsadn2JwXBw2RdB8I3l5P18TlQ4qnmwfsNoFIovMH9fDw_g2tD9swrBKfsI5kz_ac=w1280-h1280-s-no?authuser=0',
    descriptions: ['旬なお野菜をハーブ＆スパイスで美味しく漬け込みました'],
  },
  {
    name: 'ドライフルーツ',
    price: 600,
    path: 'https://lh3.googleusercontent.com/pw/ADCreHf5NzCbxJggAmvBpM9GpCjOR3-BXp1YBeQCjcP6e7a8_x4z0bvYpRmM6im4CsrtXqZvMKelgmBtwlnpbNp8JI3gs5Z5AFUKifz9UKm8ge8JIVPxkhj5p4CH0ymnv3qJKrW5gXCDxVWODPCxTxKcNul_=w1280-h1280-s-no?authuser=0',
    descriptions: [
      '大粒レーズン、クランベリー、マンゴー、ジンジャー盛り合わせ',
    ],
  },
  {
    name: 'メキシカンナチョス',
    price: 1000,
    path: 'https://lh3.googleusercontent.com/pw/ADCreHeWUV5fCfC37qxffz6eYymYhCvZkrzlhMlKFW-puGEr66dvYNH5Kz6Qm9Ovou4UkSqtuikVmir_I4DnfZR_nGXQclBb6FjQRWeWW-_QI1riwa9oKJaJV4tqpzK9_zxthALVEN20vbeEDDf_EmQ2qkn2=w1280-h1280-s-no?authuser=0',
    descriptions: ['クラフトビールと相性バツグン!'],
  },
  {
    name: 'ハムサラミ盛り合わせ',
    price: 1200,
    path: 'https://lh3.googleusercontent.com/pw/ADCreHdQNv-DO3d69qJ2PkbIvzC_bFjs3MP-t3Kf3WfBoI3mJoHlYeMmPn6FBEnQJ3fUIQPpyxykOkJcCzu48vyM8cuUTziwpOWN6ntRAbNNdfqfyJCno_RHZRH5zZiftnK0Ko2mgzRgUmgTN9I7Yqw3Tdi1=w1280-h1280-s-no?authuser=0',
    descriptions: ['カモの生ハム、サラミ、ビアソーセージ'],
  },
  {
    name: 'イカと旬野菜のジェノベーゼ',
    price: 500,
    path: 'https://lh3.googleusercontent.com/pw/ADCreHe2t1jTU0C0_ZOODzyv-SGwcyb6Wvw-o5ETV22LvhwOFQNxH0qW3oiQgKcXHK8VtXHa-ELFibOS_f_V3O8K8EfELKRtypoZ4s50Yf4VnBiwUqlKyln3UqnJ9NEazDueKZvpiGPce18bxragqaaTm3Ar=w1280-h1280-s-no?authuser=0',
    descriptions: ['バジルソースが決め手'],
  },
  {
    name: 'オリーブ盛り',
    price: 500,
    path: 'https://lh3.googleusercontent.com/pw/ADCreHfUbnaplUT2lnY0nlu8StpiRHYjw31O8LYbmjdUjL4ZEJKPRW-tp8glGFwCS_ZsLtA-aQewfVppgTp3B9TJChAqLg6-E6C5bWCHVg3GxDaXI6H6gWkerFvFiA9OLohvyt2BbcIZ-0MPruxbOdvx9sZF=w1280-h1280-s-no?authuser=0',
    descriptions: [
      'アンチョビやパプリカペーストが詰まっているおつまみオリーブ',
    ],
  },
  {
    name: 'ミックスナッツ',
    price: 500,
    path: 'https://lh3.googleusercontent.com/pw/ADCreHfyTs_YYuAXotImodeOwypJgWT9BvUI9W3WWlVFa1hepu-fbMIgEghA5BWc6A-hHtvbSCnDKSQiTT6rGn_Wx2naXjx023lxeS0KZ0kqES_GIcEorP3zxMu05BZMaAJQN1H0WevEPKOrBAhks9_O_cId=w1280-h1280-s-no?authuser=0',
    descriptions: ['Bar定番のおつまみ！', 'お口がさみしい時はこれ!'],
  },
  {
    name: 'マカロニチーズ',
    price: 800,
    path: 'https://lh3.googleusercontent.com/pw/ADCreHec_Y2AT5sy3UZ_0I3CYslMkTfw2F01zEIhGFGb15wkPHhkUiHptZJXf1gNe7iyx8teI3LBYd09tLi07yVK6KrCj1-mamJQtobp6VjJyihOUO1ltO3Yrd8CnxVpCWdEyIedyEjeRgDaV-SwVAFbH44b=w1280-h1280-s-no?authuser=0',
    descriptions: ['アメリカのソウルフードをフリーラアレンジで!'],
  },
  {
    name: 'チーズ盛り合わせ',
    price: 1200,
    path: 'https://lh3.googleusercontent.com/pw/ADCreHda1Irp-hbt5iXYV-71HSGkT2dPfooT0mYMnTNB4awE12CtakEbNBvYAcRDWe7GAxKNOrqYLFmT-Fc2XXPIB-u_xr2NFm_R8-KvS-qbXHHHcCQL1-bI-k5Hn5nj8wAG4PjggDv2R0nqoV5_2kGqzav2=w1280-h1280-s-no?authuser=0',
    descriptions: ['スモークチーズミモレット、ゴルゴンゾーラ、バゲット付き'],
  },
  {
    name: 'チーズとハムの盛り合わせ',
    price: 1500,
    path: 'https://lh3.googleusercontent.com/pw/ADCreHdNahicAKOYc_vg5H_7y8ZMa34yDIvtodnbutKXvl--MVN4h2gq8M2-C0giAyPJpRHE1JAQ12cjR7H4i2WCpckgs27_li3MTM7nd1E5fqRL44Z44DVfzplxLL08jAtGt761iOnc9Paz6SQVhpN3SaZB=w1280-h1280-s-no?authuser=0',
    descriptions: ['おつまみの贅沢セット！', 'いろいろつまめてお得です！!'],
  },
  {
    name: 'シーザーサラダ ～フリーラ風～',
    price: 1000,
    path: 'https://lh3.googleusercontent.com/pw/ADCreHdvD9hBXmBzYjWUfgz-0FVmUFb24xp6Xxy51pz9uwsI9ESyvSwBsA_uNKxa8wwDPZzAGQsUc9ihLz6b25Cc57qenaDGHcF4S0zI3Fbn123yx1ILOinmHsTUczVDiTBsgQ7RpDL0VL7TpTvczi5bVflb=w1280-h1280-s-no?authuser=0',
    descriptions: [
      'フレッシュな野菜と半熟卵が入ったオリジナルのシーザーサラダ',
    ],
  },
  {
    name: '具材盛りだくさん 彩野菜のコブサラダ',
    price: 1200,
    path: 'https://lh3.googleusercontent.com/pw/ADCreHcFB_KJLcGy9QgdRTzidUpuDsRtVSbtMAzWLpYhfYRPXmGZLi0kD1l38WDSuPfOF2JlkzjTtfogtD05PP8ts68GyxFLiJmKigMtLs3gAUodHNrxlLE9yAW3Vg3omj98vhJiqR_k1u9RhUeoDMzyAnHJ=w1280-h1280-s-no?authuser=0',
    descriptions: ['10種類以上の旬な野菜がゴロゴロ入ったスペシャルサラダ!'],
  },
  {
    name: 'フィッシュ&チップス ハーフサイズ (1~2人前)',
    price: 900,
    path: 'https://lh3.googleusercontent.com/pw/ADCreHfyVQTF4TeDIvlSW7dUL0_pNW6lcawIjXfj0LIZoR5gHOX0f9Sak0NLKx72V2OwrM88lA0U6Oe9szLWwRPROjWYxrlrXPhIhxqt_4PDnuoWBhTXD7GvPPu-yfq30axflHjCzDN6fA8-8Gikdfo-Vfjp=w1280-h1280-s-no?authuser=0',
    descriptions: ['当店1番人気メニュー！', '麦酒にはこれ！'],
  },
  {
    name: 'フィッシュ&チップス フルサイズ (3~4人前)',
    price: 1500,
    path: 'https://lh3.googleusercontent.com/pw/ADCreHdGkc5u4ENyN2HuVVbgFWnXGLSDnHcDpvsgsQefGp2MZ3owZO9H2PUEQo19GqKMG9ds0MF803a_ZPJ40Xf3vsIw6tjEKswvihbWRV7wDH3KjTFfRV0W5gOUUAZm9cnD5FYA6QYNqECDLE3ZdOjTadY1=w1280-h1280-s-no?authuser=0',
    descriptions: ['当店1番人気メニュー！', 'ボリューム満点！'],
  },
  {
    name: 'スパイシーフライドポテト',
    price: 600,
    path: 'https://lh3.googleusercontent.com/pw/ADCreHfQ3qk-PUd4qmrCSgzAFcBqbfoqakQJM0AqzWeNuZwwTNhWlwByzCH4NhLzTeHzgKAA8A475LCQWHNcnCIidxPKMWJYrEP4zhS-Qap9SFETt_2BdmthtPOE78wulaj5AIKU6o_XO2Pt4-cnDWYDXgIY=w1280-h1280-s-no?authuser=0',
    descriptions: ['スパイス香るやみつきポテト！'],
  },
  {
    name: 'チーズがけスパイシーフライドポテト',
    price: 800,
    path: 'https://lh3.googleusercontent.com/pw/ADCreHd-zuiSnYovcxFhriGO9YP-1RiF4rsy9EqaMwWQ3Z0nSlJ66v51tAaQ0Y9BTv8yqq0isQZlRLKEtNTRCwbZdezfuk6Pn6ruJbLJU1RRuC3SUcOuhGd6N31-6QPOTqZt_l47C1nM75xKkUIJz6Uzcvsa=w1280-h1280-s-no?authuser=0',
    descriptions: ['すりおろしたふわふわチーズをたっぷりと!'],
  },
  {
    name: 'アンチョビポテト',
    price: 700,
    path: 'https://lh3.googleusercontent.com/pw/ADCreHc9TVK8Oz_syfilLXIoSuYb2Xcq4DACyZ8csQa11UI2CQ3IaQJcBUw7AqYA1w5n4jyki-QjacseeLxS1_HRL8fONsqtLFC4p5Sh7CqM-5sgmCZ4Wfm-TGZ094A8yt45fYbZpRwqeyHGlyxV-RT6xN4I=w640-h640-s-no?authuser=0',
    descriptions: [
      'イタリアシチリア島産のアンチョビで自家製ソースを手作り！',
      'テッパンメニュー！!',
    ],
  },
  {
    name: 'ケイジャンフライドチキン',
    price: 900,
    path: 'https://lh3.googleusercontent.com/pw/ADCreHde3ADG0t24hIhu02tnZiwKjb6ugMXYGV3kPu2_Y-JkYTwIDOroPfMvnJD78Zyr8dfN52JtXolIiWBT_BCBiK_7znpfX6425bhr-HDUmWttY1CxEE0_KVVzHBN5fNlUbWcs_LZBhKfiJjkG9I3IAk7K=w640-h640-s-no?authuser=0',
    descriptions: [
      'ビール&ブレンドスパイスに漬け込んで鶏もも肉を2度揚げでしっとりと！',
    ],
  },
  {
    name: '目玉焼き乗せドライカレー',
    price: 1200,
    path: 'https://lh3.googleusercontent.com/pw/ABLVV84PpVD8KSztjK0pkMQ5qKHo6jGwXHKhGvTIvinQC_TfXWO2lzet0NZ5ngK64I9o2O1_5b1cKeaSEeDwoyXIod3jZFDoSvhGt5a9aW0Lh8RwIJ_AFXnzAHiU70YNLc_TYaIXaww285umu4z_Z_IsoqpW=w480-h480-s-no-gm?authuser=0',
    descriptions: [
      'こだわりのドライカレー！',
      'スパイスが効いたクセになる味です！',
    ],
  },
  {
    name: '特製タコライス',
    price: 1200,
    path: 'https://lh3.googleusercontent.com/pw/ABLVV8701USilMKvvwbvVwJxn68vZrRsBnDlHLCaL8GQDUPAl5nYctbuFUvmF-sjgL_ooPEW7IUj4PAWl8EcC9ZjAv40UIk-tNDpGUyF2I8TnbEvHo9ey7PbVfL3TQqDvd57rdkUPN8OzAF_DAoalnogm5mw=w480-h480-s-no-gm?authuser=0',
    descriptions: [
      '女性人気が高いタコライス！',
      '仕上げにチーズをバーナーで炙ってご提供します！',
    ],
  },
  {
    name: 'ベルギー産チョコブラウニー',
    price: 600,
    path: 'https://lh3.googleusercontent.com/pw/ADCreHfQgt2szlZYbg_XfBVmEsRUptGEJ0vY6FZ1pZqCtgc10mhxoaS5REG3VDwh47-yWiP7wVDMAZ4GxNBqS1zD1_FlARM3EMeSmm7zn0k1LyNbyaOQfJQagejJYRmzMZDnagNGZwVizUqWVRFJLP48l0D2=w640-h640-s-no?authuser=0',
    descriptions: ['～バニラアイスを添えて～'],
  },
  {
    name: 'チーズケーキブリュレ',
    price: 600,
    path: 'https://lh3.googleusercontent.com/pw/ABLVV85SM7AfBN-GQ67SuDkE_0Pl2ioOQZlUPEt2WpLaZVyVh_WnNu-V0_XH1SJIY5BrAbb932TZj0OpQUD63y68dz5Q3pIXM3WVU8S6UWEb0h5k2lfSD9GsKk0BZsT5BQrcAyr0W1i5EQ-074N4hvyx609F=w480-h480-s-no-gm?authuser=0',
    descriptions: [
      'チーズケーキをブリュレに！',
      'ベリーソースで仕上げました！',
    ],
  },
];
