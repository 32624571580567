import { styled } from 'styled-components';
import { FONT_FAMILY } from '../constants';

type TextProp = {
  $align?: string;
  $my?: number;
  $mx?: number;
};

export const HeaderLarge = styled.h1<TextProp>`
  font-family: ${FONT_FAMILY.HEADER_LARGE};
  font-size: 3rem;
  text-align: ${({ $align }) => $align || 'center'};
  margin: ${({ $mx, $my }) => `${$my || 0}rem ${$mx || 0}rem`};
`;

export const HeaderMiddle = styled.h2<TextProp>`
  font-family: ${FONT_FAMILY.HEADER_MIDDLE};
  font-size: 1.5rem;
  text-align: ${({ $align }) => $align || 'center'};
  margin: ${({ $mx, $my }) => `${$my || 0}rem ${$mx || 0}rem`};
`;

export const HeaderSmall = styled.h3<TextProp>`
  font-family: ${FONT_FAMILY.HEADER_SMALL};
  font-size: 1.25rem;
  text-align: ${({ $align }) => $align || 'center'};
  margin: ${({ $mx, $my }) => `${$my || 0}rem ${$mx || 0}rem`};
`;
