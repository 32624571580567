import { RootLayout, SectionLayout } from '@domains/common/components/layout';
import Contact from '@domains/contact';
import Drink from '@domains/drink';
import Food from '@domains/food';
import Instagram from '@domains/instagram';
import Recruit from '@domains/recruit';
import Shop from '@domains/shop';
import Footer from '@domains/common/components/footer';
import Header from '@domains/common/components/header';
import Top from '@domains/top';
import Course from './domains/course';

export default function App() {
  return (
    <>
      <Header />
      <RootLayout>
        <SectionLayout id="top">
          <Top />
        </SectionLayout>
        <SectionLayout id="shop">
          <Shop />
        </SectionLayout>
        <SectionLayout id="drink">
          <Drink />
        </SectionLayout>
        <SectionLayout id="food">
          <Food />
        </SectionLayout>
        <SectionLayout id="course">
          <Course />
        </SectionLayout>
        <SectionLayout id="instagram">
          <Instagram />
        </SectionLayout>
        <SectionLayout id="recruit">
          <Recruit />
        </SectionLayout>
        <SectionLayout id="contact">
          <Contact />
        </SectionLayout>
      </RootLayout>
      <Footer />
    </>
  );
}
