import React from 'react';
import { styled } from 'styled-components';
import { IMAGE } from '../constants';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

export default function ImageSlider() {
  return (
    <>
      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        slidesPerView={1} //一度に表示するスライドの数
        pagination={{
          clickable: true,
        }} //何枚目のスライドかを示すアイコン、スライドの下の方にある
        navigation //スライドを前後させるためのボタン、スライドの左右にある
        loop={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
      >
        {IMAGE.SLIDER_IMAGES.map((d, i) => (
          <SwiperSlide key={i}>
            <img width="100%" src={d} alt={`drink-${i}`} />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}

export const SplideImage = styled.img`
  width: 100%;
  heigth: auto;
`;
