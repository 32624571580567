import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';

export type IconProp = {
  name: string;
  size?: number;
  onClick?: () => void;
};

export default function Icon({ name, size = 8, onClick }: IconProp) {
  const baseProps = {
    onClick: onClick,
    size: `${size}rem`,
    style: { height: 'auto' },
  };

  switch (name) {
    case 'menu':
      return <AiOutlineMenu {...baseProps} />;
    case 'close':
      return <AiOutlineClose {...baseProps} />;
    default:
      throw new Error('存在する name を設定してください。');
  }
}
