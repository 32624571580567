import React from 'react';
import styled from 'styled-components';
import { COLOR, IMAGE, LINK } from '../constants';

export default function Footer() {
  return (
    <footer>
      <Border color={COLOR.BORDER} />
      <Image>
        <img src={IMAGE.LOGO_LARGE_WHITE} width="40%" alt="free.la"></img>
      </Image>
      <Menu>
        <a href="#top">TOP</a>
        <a href="#shop">Shop</a>
        <a href="#drink">Drink</a>
        <a href="#food">Food</a>
        <a href={LINK.INSTAGRAM} target="_blank" rel="noreferrer">
          Instagram
        </a>
        <a href="#recruit">Recruit</a>
        <a href="#contact">Contact</a>
      </Menu>

      <FollowUs>
        <p>Follow Us</p>

        <a href={LINK.INSTAGRAM} target="_blank" rel="noreferrer">
          <img src={IMAGE.INSTAGRAM_BANNER} width="64%" alt="instagram"></img>
        </a>
      </FollowUs>
      <ExMenu>
        <a href={LINK.PRICACY_POLICY} target="_blank" rel="noreferrer">
          プライバシーポリシー
        </a>
      </ExMenu>
      <Text>&copy;肉とクラフトビール free.la町田店 2023</Text>
    </footer>
  );
}

const Border = styled.hr`
  margin: 0 1rem;
`;

const Image = styled.figure`
  text-align: center;
  margin-top: 1rem;
`;

const Menu = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 1rem;

  > a {
    border: solid 1px ${COLOR.BORDER};
    padding: 1rem;
    font-size: 1.25rem;
    text-align: center;
  }
`;

const ExMenu = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin: 1rem;

  > a {
    text-decoration: underline;
  }
`;

const FollowUs = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;

  > p {
    text-align: center;
    font-size: 1.25rem;
    width: 50%;
  }

  > a {
    width: 50%;
    text-align: center;
  }
`;

const Text = styled.p`
  text-align: center;
  margin: 0.5rem 0;
`;
