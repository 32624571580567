import { CircleImage } from '@common/components/circle_images';

export const COURSES: CircleImage[] = [
  {
    name: '◆堪能コース◆【クラフトビール12種類】2.5時間飲み放題＋フリーラの名物料理含む8品',
    price: 6000,
    path: 'https://lh3.googleusercontent.com/pw/ABLVV84QK2YVia4xk_g6rjNnrHN6tM5ZSHFiYXCU5nLuU3c3FusuZhhvs5b5LPomkCJVb4t_gOTFYT3LWmWiQrkFNi1yVZ8OoBvIvNDLdVZkILOAJHXjXVQoXAc-8rDfk04jkDckhTkXL2qw9EafAUwrmMpb=w1536-h1536-s-no-gm?authuser=0',
    descriptions: [
      '利用可能人数: 2～30名様',
      '利用可能曜日: 月～日・祝日・祝前日',
      '利用可能時間: 13:00～21:00',
      '滞在可能時間: 2時間30分',
      '飲み放題: 季節限定のクラフトビールがタップで12種類',
      '',
      'コース内容',
      'クラフトビール12種類】2.5時間飲み放題＋フリーラの名物料理含む8品',
      '',
      'お料理内容',
      '・前菜盛り合わせ3種類',
      '・彩り野菜のコブサラダ',
      '・フィッシュ＆チップス',
      '・肉盛りプレート',
      '・特製タコライス',
      '・デザート盛り合わせ',
      '',
      '飲み放題内容',
      '・季節限定のクラフトビールがタップで12種類',
      '・クラフトビール 12種類',
      '・カクテル 40種類',
      '・ワイン グラス白/グラス赤',
      '・ソフトドリンク 5種類',
      '※ドリンクラストオーダー30分前',
      '※グラス交換制',
    ],
  },
  {
    name: '◆新年会コース◆【クラフトビール3種類】2.5時間飲み放題＋季節のお料理6品',
    price: 5000,
    path: 'https://lh3.googleusercontent.com/pw/ABLVV87GkpeAt_pPbofc3luwIikqtBzZcnmn7lhL9gZFzvZcT0tdhe9eMqTQrT4n_YqLHb1iL3M93p0vr3oL37RovmXCywAC8Vgj-T7mbEFMZgHKyx3_kTW_W7K86TQL-GtdpqFq5UZt4ZILRxbfEbWWL7qp=w1536-h1536-s-no-gm?authuser=0',
    descriptions: [
      '利用可能人数: 2～30名様',
      '利用可能曜日: 月～日・祝日・祝前日',
      '利用可能時間: 13:00～21:00',
      '滞在可能時間: 2時間30分',
      '',
      'コース内容',
      '【クラフトビール3種類】2.5時間飲み放題＋季節のお料理6品',
      '',
      'お料理内容',
      '・前菜盛り合わせ2種類',
      '・シーザーサラダ',
      '・フィッシュ＆チップス',
      '・肉盛りプレート',
      '・目玉焼き乗せドライカレー',
      '',
      '飲み放題内容',
      '・クラフトビール 3種類',
      '・カクテル 40種類',
      '・ワイン グラス白/グラス赤',
      '・ソフトドリンク 5種類',
      '※ドリンクラストオーダー30分前',
      '※グラス交換制',
    ],
  },
  {
    name: '◆お手軽コース◆【クラフトビール3種類】2時間飲み放題＋ビールにあう絶品料理5品',
    price: 4000,
    path: 'https://lh3.googleusercontent.com/pw/ABLVV87SE9Ggn7I83fWapULIlTgApfgBvS5rCAEj9tneiXt58r0VT4B3B7yjI0j5aJ2rbHZwTLtnPNV6O9gw52RQHsN1qw_cIaJdyPYylOrspywXFO24zaJlKuzOqceIFUSDtfwtBZbspemUHNtobJRYYV5O=w1280-h1280-s-no-gm?authuser=0',
    descriptions: [
      '利用可能人数: 2～30名様',
      '利用可能曜日: 月～日・祝日・祝前日',
      '利用可能時間: 13:00～翌2:00',
      '滞在可能時間: 2時間',
      '',
      'コース内容',
      '【クラフトビール3種類】2時間飲み放題＋ビールにあう絶品料理5品',
      '',
      'お料理内容',
      '・前菜盛り合わせ2種',
      '・ケイジャンフライドチキン&スパイシーポテト',
      '・極太ソーセージのグリル',
      '',
      '飲み放題内容',
      '・クラフトビール 3種類',
      '・カクテル 40種類',
      '・ワイン グラス白/グラス赤',
      '・ソフトドリンク 5種類',
      '※ドリンクラストオーダー30分前',
      '※グラス交換制',
    ],
  },
];
