import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import {
  COLOR,
  EMAILJS,
  ERROR_MESSAGE,
  LINK,
  SUCCESS_MESSAGE,
} from '@domains/common/constants';
import * as yup from 'yup';
import FormErrorMessage from '@common/components/form/error_message';
import emailjs from '@emailjs/browser';
import { SubmitButton } from '@common/components/button';
import { HeaderLarge } from '@common/components/text';
import { BUSINESS_TYPE, CONTACT_TYPE } from './constant';
import { ContactFormValue } from './type';
import { yupResolver } from '@hookform/resolvers/yup';

export default function ContactForm() {
  const [apiError, setApiError] = useState<string>('');
  const [successMsg, setSuccessMsg] = useState<string>('');
  const [isSubmit, setIsSubmit] = useState<boolean>(false);

  const validationSchema = yup.object({
    contactType: yup
      .mixed<ContactFormValue['contactType']>()
      .oneOf([CONTACT_TYPE.INQUIRY, CONTACT_TYPE.RECRUIT])
      .required(ERROR_MESSAGE.INPUT_REQUIRED)
      .default(CONTACT_TYPE.INQUIRY),
    name: yup.string().required(ERROR_MESSAGE.INPUT_REQUIRED),
    furiganaName: yup
      .string()
      .required(ERROR_MESSAGE.INPUT_REQUIRED)
      .matches(/^[ぁ-ん|\s]+$/u, { message: ERROR_MESSAGE.INPUT_HIRAGANA }),
    phoneNumber: yup
      .string()
      .min(10, ERROR_MESSAGE.INPUT_MIN_VALUE_PHONENUMBER)
      .max(12, ERROR_MESSAGE.INPUT_MAX_VALUE_PHONENUMBER)
      .required(ERROR_MESSAGE.INPUT_REQUIRED),
    email: yup
      .string()
      .email(ERROR_MESSAGE.INPUT_EMAIL)
      .required(ERROR_MESSAGE.INPUT_REQUIRED),
    content: yup
      .string()
      .default('')
      .when('contactType', {
        is: (val: ContactFormValue['contactType']) =>
          val === CONTACT_TYPE.INQUIRY,
        then: (schema) => schema.required(ERROR_MESSAGE.INPUT_REQUIRED),
      }),
    businessType: yup
      .mixed<ContactFormValue['businessType']>()
      .oneOf([BUSINESS_TYPE.EMPLOYEE, BUSINESS_TYPE.PART_TIMER])
      .required(ERROR_MESSAGE.INPUT_REQUIRED)
      .default(BUSINESS_TYPE.EMPLOYEE),
    selfPr: yup
      .string()
      .default('')
      .when('contactType', {
        is: (val: ContactFormValue['contactType']) =>
          val === CONTACT_TYPE.RECRUIT,
        then: (schema) => schema.required(ERROR_MESSAGE.INPUT_REQUIRED),
      }),
    question: yup.string().default(''),
    isAgree: yup
      .boolean()
      .default(false)
      .oneOf([true], ERROR_MESSAGE.INPUT_REQUIRED),
  });

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<ContactFormValue>({
    mode: 'onBlur',
    shouldUnregister: false,
    defaultValues: {
      contactType: CONTACT_TYPE.RECRUIT,
      name: '',
      furiganaName: '',
      phoneNumber: '',
      email: '',
      content: '',
      businessType: BUSINESS_TYPE.EMPLOYEE,
      selfPr: '',
      question: '',
      isAgree: false,
    },
    resolver: yupResolver(validationSchema),
  });

  // 送信ボタン押下時処理
  const onSubmit = async (data: ContactFormValue) => {
    if (isSubmit) return;

    setIsSubmit(true);
    setApiError('');
    setSuccessMsg('');

    console.log('Submitted Data', data);
    try {
      const res = await emailjs.send(
        EMAILJS.SERVICE_ID,
        data.contactType === CONTACT_TYPE.INQUIRY
          ? EMAILJS.TEMPLATE_ID.OTHER
          : EMAILJS.TEMPLATE_ID.RECRUIT,
        {
          contact_type: data.contactType,
          name: data.name,
          name_kana: data.furiganaName,
          phone_number: data.phoneNumber,
          email: data.email,
          content: data.content,
          business_type: data.businessType,
          self_pr: data.selfPr,
          question: data.question,
        },
        EMAILJS.PUBLIC_KEY
      );

      console.info(res);

      if (res.status === 200) {
        setSuccessMsg(SUCCESS_MESSAGE.EMAIL_SEND);
        setIsSubmit(false);
        return;
      }

      throw new Error(ERROR_MESSAGE.FAILED_SEND_EMAIL);
    } catch (error: unknown) {
      console.error(error);
      setApiError(
        error instanceof Error ? error.message : ERROR_MESSAGE.FAILED_SEND_EMAIL
      );
      setIsSubmit(false);
    }
  };

  return (
    <div className="form-container">
      <HeaderLarge $my={1}>Contact</HeaderLarge>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <InputWrapper>
          <Label htmlFor="contactType">お問い合わせ種類</Label>
          <Select
            id="contactType"
            $error={!!errors.contactType}
            {...register('contactType')}
          >
            <option value={CONTACT_TYPE.RECRUIT}>採用について</option>
            <option value={CONTACT_TYPE.INQUIRY}>その他</option>
          </Select>
        </InputWrapper>
        <InputWrapper>
          <Label htmlFor="name">名前 </Label>
          <InputText
            type="text"
            id="name"
            $error={!!errors.name}
            {...register('name', {
              required: '名前を入力してください。',
            })}
          />
          {errors.name && (
            <FormErrorMessage>{errors.name.message}</FormErrorMessage>
          )}
        </InputWrapper>
        <InputWrapper>
          <Label htmlFor="furiganaName">名前(ふりがな) </Label>
          <InputText
            type="text"
            id="furiganaName"
            $error={!!errors.furiganaName}
            {...register('furiganaName')}
          />
          {errors.furiganaName && (
            <FormErrorMessage>{errors.furiganaName.message}</FormErrorMessage>
          )}
        </InputWrapper>
        <InputWrapper>
          <Label htmlFor="phoneNumber">電話番号 </Label>
          <InputText
            type="text"
            id="phoneNumber"
            $error={!!errors.phoneNumber}
            {...register('phoneNumber')}
          />
          {errors.phoneNumber && (
            <FormErrorMessage>{errors.phoneNumber.message}</FormErrorMessage>
          )}
        </InputWrapper>
        <InputWrapper>
          <Label htmlFor="email">メールアドレス </Label>
          <InputText
            type="email"
            id="email"
            $error={!!errors.email}
            {...register('email')}
          />
          {errors.email && (
            <FormErrorMessage>{errors.email.message}</FormErrorMessage>
          )}
        </InputWrapper>

        {watch('contactType') === CONTACT_TYPE.RECRUIT && (
          <>
            <InputWrapper>
              <Label htmlFor="businessType">希望職種</Label>
              <Select
                id="businessType"
                $error={!!errors.businessType}
                {...register('businessType')}
              >
                <option value={BUSINESS_TYPE.EMPLOYEE}>
                  {BUSINESS_TYPE.EMPLOYEE}
                </option>
                <option value={BUSINESS_TYPE.PART_TIMER}>
                  {BUSINESS_TYPE.PART_TIMER}
                </option>
              </Select>
            </InputWrapper>
            <InputWrapper>
              <Label htmlFor="selfPr">志望動機・自己PR</Label>
              <InputTextarea
                $error={!!errors.selfPr}
                id="selfPr"
                {...register('selfPr')}
              />
              {errors.selfPr && (
                <FormErrorMessage>{errors.selfPr.message}</FormErrorMessage>
              )}
            </InputWrapper>
            <InputWrapper>
              <Label htmlFor="question">ご質問</Label>
              <InputTextarea
                id="question"
                $error={!!errors.question}
                {...register('question')}
              />
              {errors.question && (
                <FormErrorMessage>{errors.question.message}</FormErrorMessage>
              )}
            </InputWrapper>
          </>
        )}

        {watch('contactType') === CONTACT_TYPE.INQUIRY && (
          <>
            <InputWrapper>
              <Label htmlFor="content">お問い合わせ内容 </Label>
              <InputTextarea
                id="content"
                $error={!!errors.content}
                {...register('content', {
                  required: 'お問い合わせ内容を入力してください。',
                })}
              />
              {errors.content && (
                <FormErrorMessage>{errors.content.message}</FormErrorMessage>
              )}
            </InputWrapper>
          </>
        )}

        <InputWrapper $row>
          <InputText
            type="checkbox"
            id="isAgree"
            $width={8}
            $error={!!errors.isAgree}
            {...register('isAgree')}
          />
          <Label htmlFor="isAgree" $checkbox>
            <Link href={LINK.PRICACY_POLICY} target="_blank" rel="noreferrer">
              プライバシーポリシー
            </Link>
            に同意する。
          </Label>
        </InputWrapper>
        {errors.isAgree && (
          <FormErrorMessage>{errors.isAgree.message}</FormErrorMessage>
        )}

        <SubmitButton type="submit" disabled={isSubmit}>
          送信
        </SubmitButton>

        {apiError && <FailedMessage>{apiError}</FailedMessage>}
        {successMsg && <SuccessMessage>{successMsg}</SuccessMessage>}
      </Form>
    </div>
  );
}

const SuccessMessage = styled.p`
  border: solid 1px ${COLOR.SUCCESS_BORDER};
  background: ${COLOR.SUCCESS_BG};
  padding: 0.5rem;
  border-radius: 8px;
  margin-top: 1rem;
  font-size: 1rem;
`;
const FailedMessage = styled.p`
  border: solid 1px ${COLOR.FAILED_BORDER};
  background: ${COLOR.FAILED_BG};
  padding: 0.5rem;
  border-radius: 8px;
  margin-top: 1rem;
  font-size: 1rem;
`;

type LabelProp = {
  $checkbox?: boolean;
};
const Label = styled.label<LabelProp>`
  font-size: 1.25rem;
  font-weight: bold;

  &::before {
    content: '';
    border-left: ${({ $checkbox }) =>
      $checkbox ? 'none' : `solid 8px ${COLOR.FORM_LABEL_BORDER}`};
    margin-right: ${({ $checkbox }) => ($checkbox ? '0' : '0.75rem')};
  }
`;

type CommonInputProp = {
  $error?: boolean;
  $width?: number;
};
const InputText = styled.input<CommonInputProp>`
  height: 48px;
  border: solid 1px
    ${({ $error }) =>
      $error ? COLOR.FORM_INPUT_BORDER_ERROR : COLOR.FORM_INPUT_BORDER};
  border-radius: 8px;
  padding: 0 1rem;
  width: ${({ $width }) => `${$width}%` || 'auto'};
`;
const InputTextarea = styled.textarea<CommonInputProp>`
  height: 232px;
  resize: vertical;
  border: solid 1px
    ${({ $error }) =>
      $error ? COLOR.FORM_INPUT_BORDER_ERROR : COLOR.FORM_INPUT_BORDER};
  border-radius: 8px;
  padding: 1rem;
`;
const Select = styled.select<CommonInputProp>`
  height: 48px;
  border: solid 1px
    ${({ $error }) =>
      $error ? COLOR.FORM_INPUT_BORDER_ERROR : COLOR.FORM_INPUT_BORDER};
  border-radius: 8px;
  padding: 0 1rem;
  color: ${COLOR.FORM_TEXT};
  font-size: 1rem;
`;

const Form = styled.form``;

type InputWrapperProp = {
  $row?: boolean;
};
const InputWrapper = styled.div<InputWrapperProp>`
  display: flex;
  flex-direction: ${({ $row }) => ($row ? 'row' : 'column')};
  justify-content: ${({ $row }) => ($row ? 'center' : 'inherit')};
  align-items: ${({ $row }) => ($row ? 'center' : 'inherit')};

  &:not(:first-child) {
    margin-top: 1rem;
  }

  > ${InputText}, ${InputTextarea}, ${Select} {
    margin-top: ${({ $row }) => ($row ? '0' : '0.25rem')};
    margin-right: ${({ $row }) => ($row ? '1' : '0')}rem;
  }
`;

const Link = styled.a`
  text-decoration: underline;
`;
