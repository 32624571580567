import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { COLOR, FONT_FAMILY, IMAGE, LINK, Z_INDEX } from '@common/constants';
import Icon from './icon';
import { Ref } from '@/types';

export default function Header() {
  const ref = useRef<Ref>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [headerHeight, setHeaderHeight] = useState<number>(0);

  useEffect(() => setHeaderHeight(ref.current?.offsetHeight || 0), [ref]);
  useEffect(() => {
    // メニューオープン中はスクロール禁止する
    const root = document.getElementById('root');

    if (root) {
      root.style.height = isOpen ? '100vh' : 'auto';
      root.style.overflow = isOpen ? 'hidden' : 'auto';
    }
  }, [isOpen]);

  return (
    <>
      <HeaderContent ref={ref as React.LegacyRef<HTMLElement>}>
        <a href="/">
          <img src={IMAGE.LOGO_LARGE_WHITE} width="32%" alt="free.la" />
        </a>
        <Icon
          name={isOpen ? 'close' : 'menu'}
          onClick={() => setIsOpen(!isOpen)}
        />
      </HeaderContent>

      {isOpen && (
        <Menu $pt={headerHeight}>
          <a onClick={() => setIsOpen(false)} href="#top">
            TOP
          </a>
          <a onClick={() => setIsOpen(false)} href="#shop">
            SHOP
          </a>
          <a onClick={() => setIsOpen(false)} href="#drink">
            DRINK
          </a>
          <a onClick={() => setIsOpen(false)} href="#food">
            FOOD
          </a>
          <a
            onClick={() => setIsOpen(false)}
            href={LINK.INSTAGRAM}
            target="_blank"
            rel="noreferrer"
          >
            INSTAGRAM
          </a>
          <a onClick={() => setIsOpen(false)} href="#recruit">
            RECRUIT
          </a>
          <a onClick={() => setIsOpen(false)} href="#contact">
            CONTACT
          </a>
        </Menu>
      )}
    </>
  );
}

const HeaderContent = styled.header`
  width: 100%;
  position: fixed;
  top: 0;
  z-index: ${Z_INDEX.HEADER};
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${COLOR.HEADER_BG};
`;

type MenuProp = { $pt?: number };
const Menu = styled.nav<MenuProp>`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: scroll;
  min-height: 100%;
  position: absolute;
  z-index: ${Z_INDEX.HEADER_MENU};
  background: ${COLOR.MENU_BG};
  padding-top: ${({ $pt }) => $pt || 0}px;
  padding-bottom: 1rem;
  font-family: ${FONT_FAMILY.MENU};
  font-size: 3rem;
  text-align: center;

  > a {
    color: ${COLOR.MENU_TEXT};
    border-bottom: solid 2px ${COLOR.MENU_TEXT};

    &:not(:first-child) {
      margin-top: 1.5rem;
    }
  }
`;
