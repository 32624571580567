import { styled } from 'styled-components';
import { COLOR } from '../constants';
import { ButtonHTMLAttributes, PropsWithChildren } from 'react';

export default function Button({ children }: PropsWithChildren) {
  return <DefaultButton>{children}</DefaultButton>;
}

type LinkButtonProp = {
  href: string;
};
export function LinkButton({
  href,
  children,
  ...buttonProps
}: PropsWithChildren<
  LinkButtonProp & ButtonHTMLAttributes<HTMLButtonElement>
>) {
  return (
    <a href={href}>
      <Button {...buttonProps}>{children}</Button>
    </a>
  );
}

export const DefaultButton = styled.button`
  display: block;
  color: ${COLOR.BUTTON_TEXT};
  text-align: center;
  vertical-align: middle;
  text-decoration: none;
  position: relative;
  padding: 1rem 4rem;
  font-weight: bold;
  background: ${COLOR.BUTTON_BG};
  border: solid 1px ${COLOR.BUTTON_BORDER};
  cursor: pointer;
  width: 100%;

  &::before {
    content: '';
    position: absolute;
    top: -5px;
    left: -5px;
    width: 100%;
    height: calc(100% - 1px);
    border: solid 1px ${COLOR.BUTTON_BORDER};
  }
`;

export const SubmitButton = styled.button`
  margin-top: 1rem;
  width: 100%;
  padding: 0.5rem;
  background: ${COLOR.SUBMIT_BUTTON_BG};
  color: ${COLOR.SUBMIT_BUTTON_TEXT};
  font-size: 1.25rem;
  border-radius: 8px;
  appearance: none;

  &:disabled {
    background: ${COLOR.SUBMIT_BUTTON_DISABLED_BG};
  }

  &:hover {
    opacity: 0.8;
  }

  &:active {
    background: ${COLOR.SUBMIT_BUTTON_TEXT};
    color: ${COLOR.SUBMIT_BUTTON_BG};
  }
`;
