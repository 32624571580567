import styled from 'styled-components';
import Icon from './icon';
import { COLOR, Z_INDEX } from '../constants';
import { HeaderMiddle } from './text';
import { PropsWithChildren } from 'react';

type ModalProps = {
  onClose: () => void;
};
export default function Modal({
  onClose,
  children,
}: PropsWithChildren<ModalProps>) {
  return (
    <ModalOverlay>
      <ModalContentWrapper>
        <ModalCloseButton>
          <Icon name="close" size={2} onClick={onClose} />
        </ModalCloseButton>
        {children}
      </ModalContentWrapper>
    </ModalOverlay>
  );
}

type DetailModalProps = {
  title: string;
  details: string[];
  imagePath: string;
  price: number;
  onClose: () => void;
};
export function DetailModal({
  title,
  details,
  imagePath,
  price,
  onClose,
}: DetailModalProps) {
  return (
    <Modal onClose={onClose}>
      <ModalContent>
        <HeaderMiddle $align="left">{title}</HeaderMiddle>
        <img src={imagePath} alt={title} />
        <Price>
          <p>
            <PriceNumber>{price.toLocaleString()}</PriceNumber>
            <PriceSubText>円（税込）</PriceSubText>
          </p>
        </Price>
        <ModalDetails>
          {details.map((d, i) => (d ? <p key={i}>{d}</p> : <br key={i} />))}
        </ModalDetails>
      </ModalContent>
    </Modal>
  );
}

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: ${Z_INDEX.MODAL};
`;

const ModalContentWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background: ${COLOR.MODAL_BG};
  margin: 1rem;
  padding: 1rem;
  border-radius: 0.75rem;
  max-height: 88%;
  overflow: scroll;
`;

const ModalContent = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: start;
  flex-direction: column;

  > img {
    width: 100%;
    margin-top: 1rem;
  }
`;

const ModalCloseButton = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: end;
`;

const ModalDetails = styled.div`
  margin-top: 0.5rem;
`;

const Price = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: end;
  color: ${COLOR.PRICE_TEXT};
`;
const PriceNumber = styled.span`
  font-size: 1.25rem;
  font-weight: bold;
`;
const PriceSubText = styled.span`
  font-size: 0.75rem;
`;
