import { styled } from 'styled-components';

export const RootLayout = styled.main`
  margin-top: 53px;
  padding: 1rem;
`;

export const SectionLayout = styled.section`
  margin: 1.5rem 0;
`;

export type Content = {
  subtitle: string;
  contents: string[];
};

export type DottedLayoutProps = {
  title: string;
  contents: Content[];
};
export function DottedLayout(p: DottedLayoutProps) {
  return (
    <Info>
      <dt>{p.title}</dt>
      <ul>
        {p.contents.map((content, i) => (
          <li key={i}>
            <SubTitle key={i}>&#12298;{content.subtitle}&#12299;</SubTitle>

            {content.contents.map((c, ci) => (
              <LayoutContent key={ci}>&#9632;&nbsp;{c}</LayoutContent>
            ))}
          </li>
        ))}
      </ul>
    </Info>
  );
}

const Info = styled.dl`
  border: dashed 1px #000;
  padding: 0 0.75rem 0.75rem 0.75rem;
  margin-top: 1rem;

  > dt {
    text-align: center;
    font-weight: bold;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    border-bottom: 1px solid;
    padding-bottom: 0.75rem;
  }

  > ul {
    padding: 1rem;

    > li {
      &:not(:first-child) {
        margin-top: 1rem;
      }

      > dd {
        &:first-child {
          margin-bottom: 0.5rem;
        }
      }
    }
  }
`;

const SubTitle = styled.dd`
  text-align: center;
  font-size: 0.75rem;
`;
const LayoutContent = styled.dd`
  font-size: 0.75rem;
`;
